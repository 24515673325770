<template>
  <div id="app">
    <div v-if="current_route == 'invoice'" style="background: #fff;">
      <router-view/>
    </div>
    <div v-else-if="current_route == 'contract'" style="background: #fff;">
      <router-view/>
    </div>
    <div v-else-if="current_route == 'handover'" style="background: #fff;">
      <router-view/>
    </div>
    <div v-else-if="current_route == 'return'" style="background: #fff;">
      <router-view/>
    </div>
    <div v-else>
      <div class="app-topbar">
        <div class="container cf">
          <div class="topbar-left">
            <div class="topbar-wrap">
              <span class="material-icons">call</span>
              <span>24/7 Hotline: 0157 836 661 61</span>
            </div>
            <div class="topbar-wrap">
              <span class="material-icons">email</span>
              <span>info@intime-autovermietung.de</span>
            </div>
          </div>
          <div class="topbar-right">
            <a href="https://www.facebook.com/InTimeAutovermietung/" target="_blank" class="topbar-wrap">
              <span class="material-icons"><img src="./assets/images/fb_icon.svg" height="18" style="display:block;margin-top:-2px"></span>
              <span>Folge uns auf Facebook</span>
            </a>
            <!--<div class="topbar-wrap">
              <span class="material-icons">language</span>
              <span>DE</span>
            </div>-->
            <span v-if="isLoggedIn">
              <router-link v-if="user.staff" to="/admin/dashboard" class="topbar-admin">
                <span class="material-icons">admin_panel_settings</span>
                <span>Admin</span>
              </router-link>
            </span>
          </div>
        </div>
      </div>
      <div class="app-header cf">
        <div class="container cf">
          <ul class="nav nav-main cf">
            <li>
              <router-link to="/reservations/new">
                <i class="material-icons">today</i>
                <span>Reservierung</span>
              </router-link>
            </li>
            <li>
              <router-link to="/vehicles">
                <i class="material-icons">directions_car</i>
                <span>Fahrzeuge</span>
              </router-link>
            </li>
            <li>
              <router-link to="/locations">
                <i class="material-icons">map</i>
                <span>Standorte</span>
              </router-link>
            </li>
          </ul>
          <router-link to="/" class="logo"><img src="./assets/images/logo.png" width="143" height="62"></router-link>
          <span class="resp-nav" @click="toggle_nav">☰<i>Menü</i></span>
          <ul v-if="show_nav" class="nav nav-resp cf">
            <li>
              <router-link to="/reservations/new">
                <i class="material-icons">today</i>
                <span>Reservierung</span>
              </router-link>
            </li>
            <li>
              <router-link to="/vehicles">
                <i class="material-icons">directions_car</i>
                <span>Fahrzeuge</span>
              </router-link>
            </li>
            <li>
              <router-link to="/locations">
                <i class="material-icons">map</i>
                <span>Standorte</span>
              </router-link>
            </li>
            <li>
              <router-link to="/contact">
                <i class="material-icons">contact_support</i>
                <span>Kontakt</span>
              </router-link>
            </li>
            <li v-if="isLoggedIn">
              <router-link to="/account/overview">
                <i class="material-icons">account_circle</i>
                <span>{{ user.first_name }} {{ user.last_name }}</span>
              </router-link>
            </li>
            <li v-if="isLoggedIn">
              <router-link v-if="user.staff" to="/admin/dashboard" class="topbar-admin">
                <i class="material-icons">admin_panel_settings</i>
                <span>Admin</span>
              </router-link>
            </li>
            <li v-if="isLoggedIn">
              <a @click="logout" href="#">
                <i class="material-icons">exit_to_app</i>
                <span class="nav-txt">Logout</span>
              </a>
            </li>
            <li v-else>
              <router-link to="/auth/sign_in">
                <i class="material-icons">account_circle</i>
                <span>Account</span>
              </router-link>
            </li>
          </ul>
          <ul class="nav nav-main nav-right cf">
            <li>
              <router-link to="/contact">
                <i class="material-icons">contact_support</i>
                <span>Kontakt</span>
              </router-link>
            </li>
            <li v-if="isLoggedIn">
              <router-link to="/account/overview">
                <i class="material-icons">account_circle</i>
                <span>{{ user.first_name }} {{ user.last_name }}</span>
              </router-link>
            </li>
            <li v-if="isLoggedIn">
              <a @click="logout" href="#">
                <span class="material-icons">exit_to_app</span>
                <span class="nav-txt">Logout</span>
              </a>
            </li>
            <li v-else>
              <router-link to="/auth/sign_in">
                <i class="material-icons">account_circle</i>
                <span>Account</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <section class="body-wrap">
        <router-view/>
      </section>
      <footer class="site-footer">
        <div class="container cf">
          <p>Copyright © 2021 InTime Autovermietung</p>
          <ul class="nav nav-footer cf">
            <li><router-link to="/privacy">Datenschutz</router-link></li>
            <li><router-link to="/terms">AVB</router-link></li>
            <li><router-link to="/legal">Impressum</router-link></li>
            <li><router-link to="/contact">Kontakt</router-link></li>
          </ul>
        </div>
      </footer>

      <notifications group="alert" position="top center" classes="vue-notification alert-notification" />

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLoggedIn }
  },
  data () {
    return {
      show_nav: false,
      user: {},
      current_route: ""
    }
  },
  methods: {
    get_user() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/user', { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        this.user = response.data.user;
      })
      .catch(error => {
        if (error.response.status === 401) {
          if (this.$store.getters.isLoggedIn == true) {
            this.$store.commit('logout', '');
            this.$router.push('/');
          }
        }
      })
      this.current_route = this.$router.currentRoute.name;
    },
    logout() {
      this.$store.commit('logout', '');
      this.$router.push('/');
    },
    toggle_nav() {
      if (this.show_nav == true) {
        this.show_nav = false;
      } else {
        this.show_nav = true;
      }
    }
  },
  mounted () {
    this.get_user();
  },
  watch:{
    $route (to, from){
      console.log(to);
      console.log(from);
      this.get_user();
      this.show_nav = false;
    }
  }
}
</script>

<style lang="scss">
@import 'assets/css/fonts.scss';
@import 'assets/css/normalize.css';
@import 'assets/css/skadi.css';

body {
  background: #F9F9F9;
}

a {
  cursor: pointer;
}

a:hover {
  opacity: 0.8;
}

.button {
  border-radius: 5px;
}

.button.button-orange {
  background: #FF9000;
  color: #fff;
}

.button-grey {
  background-color: #f1f1f1 !important;
  color: #444;
}

.form-checkbox {

  a {
    color: #444;
    font-weight: 600;
  }
}

.alert-danger {
  position: relative;
  padding: .75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-info {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-success {
  position: relative;
  padding: .75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.button {

  span {
    display: inline-block;
    vertical-align: middle;
  }

  span:first-child {
    margin-right: 5px;
  }
}

.status {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #f9f9f9;
}

.status-green {
  background: green;
}

.status-red {
  background: red;
}

.status-black {
  background: #444;
}

.status-yellow {
  background: yellow;
}

.loading-wrap {
  padding: 80px 0;
}

.loading-spinner {
  display: block;
  height:24px;
  width:24px;
  margin:14px auto 0 auto;
  position:relative;
  -webkit-animation:rotation .6s infinite linear;
  -moz-animation:rotation .6s infinite linear;
  -o-animation:rotation .6s infinite linear;
  animation:rotation .6s infinite linear;
  border-left:3px solid #314053;
  border-right:3px solid #f1f1f1;
  border-bottom:3px solid #f1f1f1;
  border-top:3px solid #f1f1f1;
  border-radius:100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform:rotate(0deg)
  }
  to {
    -webkit-transform:rotate(359deg)
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform:rotate(0deg)
  }
  to {
    -moz-transform:rotate(359deg)
  }
}
@-o-keyframes rotation {
  from {
    -o-transform:rotate(0deg)
  }
  to {
    -o-transform:rotate(359deg)
  }
}
@keyframes rotation {
  from {
    transform:rotate(0deg)
  }
  to {
    transform:rotate(359deg)
  }
}

.well {
  border: 1px solid #f1f1f1;
  padding: 30px;
  margin-bottom: 20px;

  h3 {
    margin-top: 0;
  }
}

.badge {
  display: inline-block;
  padding: .45em .6em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.badge-yellow {
  color: #212529;
  background-color: #ffc107;
}

.badge-green {
  color: #fff;
    background-color: #28a745;
}

.badge-black {
  color: #fff;
  background-color: #444;
}

.badge-red {
  color: #fff;
  background-color: #dc3545;
}

.form .form-wrap .form-input {
  background: #F9F9F9 !important;
}

.app-topbar {
  width: 100%;
  height: 34px;
  background: #444;
  position: relative;
  z-index: 999999;
  color: #fff;

  .topbar-left {
    float: left;

    .topbar-wrap {
      margin: 0 1px;
      border-right: 1px solid #252525;
      padding: 7px 16px;
      font-size: 12px;
      font-weight: 600;
      display: inline-block;
      color: #fff;

      span.material-icons {
        font-size: 20px;
        margin-right: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .topbar-wrap:first-child {
      border-left: 1px solid #252525;
    }
  }

  .topbar-right {
    float: right;

    .topbar-wrap {
      margin: 0 1px;
      border-left: 1px solid #252525;
      padding: 7px 16px;
      font-size: 12px;
      font-weight: 600;
      display: inline-block;
      color: #fff;

      span.material-icons {
        font-size: 20px;
        margin-right: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .topbar-wrap:last-child {
      border-right: 1px solid #252525;
    }

    .topbar-admin {
      background: #252525;
      padding: 7px 16px;
      font-size: 12px;
      font-weight: 600;
      display: inline-block;
      color: #fff;

      span.material-icons {
        font-size: 20px;
        margin-right: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.app-header {
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.2);
  position: relative;
  height: 75px;

  .logo {
    position: absolute;
    display: block;
    width: 175px;
    height: 75px;
    left: calc(50% - 87px);
    display: block;
    padding: 12px 20px 10px 20px;

    img {
      width: auto;
      max-height: 50px;
    }
  }

  .nav-main {
    float: left;
    margin: 0;
    padding: 0;

    li {
      float: left;
      list-style-type: none;

      a {
        display: block;
        color: #444;
        padding: 27px 15px 24px 15px;
        font-weight: 600;
        font-size: 18px;

        i {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  .nav-right {
    float: right;
  }
}

.body-wrap {
  width: 1200px;
  margin: 20px auto;
  background: #fff;
}

.site-footer {
  width: 100%;
  padding: 10px 0;
  background: #222;
  color: #fff;

  p {
    float: left;
  }

  .nav-footer {
    float: right;

    li {
      float: left;
      list-style-type: none;

      a {
        display: block;
        color: #fff;
        margin-left: 25px;
      }
    }
  }
}

.admin-area {
  padding: 30px;

  .nav-admin {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;

      a {
        display: block;
        margin-bottom: 10px;
        font-weight: 600;
        color: #444;
        font-size: 17px;
        padding: 10px;
        border-radius: 5px;

        i, span {
          display: inline-block;
          vertical-align: middle;
        }

        i {
          margin-right: 15px;
        }

        span {
          margin-top: 2px;
        }
      }

      a.router-link-active {
        color: #FF9000;
      }

      ul {
        margin: -20px 0 20px 0;
        padding: 20px 0 0 47px;

        li {

          a {
            font-size: 16px;
            padding: 2px;
            color: #444;
          }
        }
      }
    }

    li:last-child {

      a {
        margin-bottom: 0;
      }
    }

    li.active {

      a {
        color: #FF9000;
        background: #f9f9f9;
      }


      ul {

        li {

          a {
            font-size: 14px;
            padding: 2px;
            color: #444;
          }
        }

        li.active {

          a {
            color: #FF9000 !important;
          }
        }
      }
    }
  }

  .admin-header {

    h3 {
      float: left;
      font-size: 32px;
      font-weight: 400;
      margin: 0;
    }

    .nav-header {
      float: left;
      font-size: 26px;
      font-weight: 400;
      margin: 3px 0;
      padding: 0;

      li {
        float: left;
        list-style-type: none;
        margin: 0 30px 0 0;
        padding: 0;
        position: relative;

        a {
          display: block;
          color: #999;
        }
      }

      li a::after {
        position: absolute;
        content: "›";
        right: -20px;
        top: 0;
      }
    }

    .header-actions {
      float: right;

      p {
        margin: 0;
      }
    }
  }

  .admin-body {
    margin-top: 20px;
  }
}

.home-advantages {

  .icon {
    margin-bottom: 40px;

    img {
      height: 50px;
    }
  }
}

.search-wrap {
  margin-bottom: 20px;

  .search-field {
    display: block;
    width: 100%;
    padding: 14px;
    line-height: 1.5;
    background: #F5FAFB;
    border: 1px solid #D7E3E6;
    -webkit-box-shadow: inset 0 0 3px 0 rgba(215,227,230,0.40);
    box-shadow: inset 0 0 3px 0 rgba(215,227,230,0.40);
    border-radius: 4px;
    font-size: 16px;
  }
}

.nav-tabs {
  margin: 15px 0;
  padding: 0;
  border-bottom: 2px solid #f1f1f1;

  li {
    list-style-type: none;
    float: left;

    a {
      display: block;
      padding: 14px;
      color: #444;
      position: relative;
      bottom: -2px;
      border-bottom: 2px solid #f1f1f1;
    }
  }

  li.active {

    a {
      border-bottom: 2px solid #FF9000;
      color: #FF9000;
      font-weight: 600;
    }
  }
}

.vc-w {
  display: inline-block;
  background: #f9f9f9;
  padding: 4px 8px;
  margin: 0 1px;
  border-radius: 3px;

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
  }

  span.material-icons {
    font-size: 14px;
    margin-right: 3px;
  }
}

.location {
  padding: 10px;
}

.location-intro {
  width: 100%;
  background: #f9f9f9;
  padding: 30px;
  background-size: cover;
  color: #444;

  img {
    max-width: 100%;
  }

  h1 {
    margin: 0 0 20px 0;
  }

  p.lead {
    margin: 0;
  }

}

.location-body {
  padding: 20px;

  h3 {
    margin: 0 0 6px 0;
    font-size: 20px;
  }

  p {
    font-size: 12px !important;
    font-weight: 400;
    margin: 0 0 4px 0;

    span.material-icons {
      font-size: 16px;
      margin-right: 5px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.page {
  padding: 30px;

  a {
    color: #444;
    font-weight: 600;
  }

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 28px;
    color: #FF9000;
  }
}

.location-details {
  p {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 6px 0;

    span.material-icons {
      font-size: 18px;
      margin-right: 5px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}










.home {
  padding: 10px;
}

.home-intro {
  width: 100%;
  background: #f9f9f9;
  padding: 40px;
  background-image: url('./assets/home-head.jpg');
  background-size: cover;
  color: #fff;

  .lead {
    font-size: 18px;
    line-height: 1.25;
  }

  .datepicker {
    color: #444;
    position: relative;

    .asd__wrapper--datepicker-open {
      left: 0 !important;
      top: 77px !important;
    }

    .asd__keyboard-shortcuts-trigger {
      display: none;
    }
  }

  h1 {
    margin: 0 0 20px 0;
    font-size: 30px;
  }

  p {
    margin: 0;
  }

  .home-reservation {
    background: #fff;
    padding: 30px;
    margin-left: 40px;

    p {
      margin-top: 30px;
    }

    .nav-categories {
      margin: 0 0 20px 0;
      padding: 0;
      border-bottom: 1px solid #f1f1f1;

      li {
        float: left;
        list-style-type: none;
        color: #444;

        a {
          display: block;
          cursor: pointer;
          padding: 0 12px 8px 12px;
          font-weight: 600;
          border-bottom: 1px solid #f1f1f1;
          position: relative;
          bottom: -1px;
        }

        a.active {
          color: #FF9000;
          border-bottom: 1px solid #FF9000;
        }
      }
    }
  }
}

.home-entry {
  width: 100%;
  height: 225px;
  background: #f9f9f9;
  margin-top: 10px;
  padding: 40px 20px;
  text-align: center;
  background-image: url('./assets/home-entry-2.jpg');
  background-size: cover;
  background-position: center center;
  color: #fff;

  .discount {
    display: inline-block;
    padding: 7px 14px;
    border-radius: 4px;
    background: rgba(0,0,0,0.5);
    margin-bottom: 10px;

    .material-icons {
      margin-right: 5px;
      font-size: 18px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
    }
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  .price {
    margin: 10px 0;
    font-size: 30px;
    font-weight: 600;
  }
}

.col-8:nth-child(2) .home-entry {
  background-image: url('./assets/home-entry.jpg');
}

.col-8:nth-child(3) .home-entry {
  background-image: url('./assets/home-entry-3.jpg');
}

.home-advantages {
  background: #f9f9f9;
  margin-top: 10px;
  padding: 80px 40px;
  text-align: center;

  h3 {
    margin: 0;
    font-size: 30px;
  }

  p.lead {
    margin: 10px 0 80px 0;
    font-size: 20px;
  }

  h4 {
    margin: 0;
    font-size: 20px;
  }

  p.icon {
    margin-bottom: 20px;

    span {
      font-size: 50px;
    }
  }
}

.home-about {
  padding: 70px 60px;

  h3 {
    margin: 0;
    font-size: 26px;
    font-weight: 400;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3;
  }

  h4 {
    margin: 40px 0 20px 0;
    font-size: 22px;
    font-weight: 400;
  }

}

.home-location {
  margin-bottom: 20px;
  border: 1px solid #f1f1f1;
  display: block;
  color: #444;

  .location-img {
    width: 100%;
    height: 150px;
    background: #f1f1f1;
  }

  .location-body {
    padding: 14px;
    font-size: 18px;
    font-weight: 600;

    h3 {
      margin: 0 0 6px 0;
      font-size: 20px;
      font-weight: 600;
    }

    p {
      font-size: 12px !important;
      font-weight: 400;
      margin: 0 0 4px 0;

      span.material-icons {
        font-size: 16px;
        margin-right: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.content-wrap {
  padding: 20px;

  h1 {
    margin: 0;
    font-weight: 600;
    font-size: 28px;
    color: #FF9000;
  }

  p {
    font-size: 18px;
    line-height: 1.4;
  }

  .vehicle-wrap {
    border: 1px solid #f1f1f1;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;

    .vehicle-img {
      float: left;
      margin-right: 30px;

      p {
        margin: 0;
      }
    }

    .vehicle-body {
      float: left;
      width: calc(100% - 450px);

      h3 {
        margin: 15px 0 0 0;
        color: #444;
        font-size: 22px;

        small {
          margin-top: 4px;
          display: block;
          font-weight: 400;
          color: #888;
        }
      }

      p {
        margin: 15px 0 0 0;
        color: #888;
        font-size: 18px;
      }

      .vc-w {
        padding: 10px 16px;

        span {
          font-size: 16px;
        }
      }
    }
  }
}

.car-categories {

  .vehicle-body {

    .vc-w {
      padding: 6px 8px;

      span {
        font-size: 14px;
      }
    }
  }

}

.payment-options {
  margin-bottom: 40px;
  cursor: pointer;

  .option-wrap {
    text-align: center;

    label {
      padding-top: 10px;
      cursor: pointer;
      background: #F9F9F9;
      border: 1px solid #ECECEC;
      display: block;
      width: 100%;
    }
  }
}

.payment-method-wrap {
  background: #F9F9F9;
  border: 1px solid #ECECEC;
  padding: 30px;

  h3 {
    margin-top: 0;
    font-weight: 400;
  }

  .disclaimer {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 12px;
    opacity: 0.6;
  }

  .form-wrap .form-input {
    background-color: #FFF;
  }
}


.extra-wrap {
  background: #F9F9F9;
  border: 1px solid #E6E6E6;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: block;

  input {
    float: left;
    margin-top: 20px;
    margin-right: 20px;
  }

  .extra-wrap-body {
    float: left;
    width: calc(100% - 120px);

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
    }

    p {
      margin: 8px 0 0 0;
      color: #888;
      font-size: 18px;
    }
  }

  p.price {
    float: right;
    margin: 16px 0 0 0;
    color: #888;
    font-size: 18px;
  }

  label {
    float: left;
    cursor: pointer;
    margin-left: 20px;

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
    }

    p {
      margin: 8px 0 0 0;
      color: #888;
      font-size: 18px;
    }
  }

  .price {
    float: right;
  }
}

.extra-wrap:hover {
  border: 1px solid #aaa;
}




#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0 ;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

.hidden {
  display: none;
}

.StripeElement {
  height: 50px;
  min-height: 40px;
  padding: 14px 18px;
  border: 1px solid #ECECEC;
  color: #444;
  border-radius: 4px;
  background: #fff;
}

.StripeElement--focus {
  border-color: #aeaeae;
  outline: 0;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.vue-notification-group {
  margin-top: 10px;
  text-align: center !important;
  z-index: 99999999999 !important;
}

.alert-notification {
  border-left: 0 !important;
  border-radius: 3px;

  .notification-title {
    text-align: center;
  }

  .notification-content {
    text-align: center;
  }
}

.payment-method-wrap {
  position: absolute;
  left: -50000px;
  top: -50000px;
  visibility: hidden;
}

.payment-method-wrap.payment-visible {
  position: relative;
  left: 0;
  top: 0;
  visibility: visible;
}

@media (max-width: 770px) {

  .content-wrap .vehicle-wrap .vehicle-img {
    float: none !important;
  }

  .content-wrap .vehicle-wrap .vehicle-img img {
    max-width: 100% !important;
  }

  .content-wrap .vehicle-wrap .vehicle-body {
    float: none !important;
    width: 100% !important;
  }

  #pay-wrap .col-12 {
    width: 100%;
  }

}

@media (min-width: 1200px) {



}

@import 'assets/css/resp.css';

</style>
