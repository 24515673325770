import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'intime-reservation'
})

export default new Vuex.Store({
  state: {
    reservation: {
      current_step: 1,
      period: {},
      vehicle: {},
      extras: {},
      personal_data: {}
    },
    intime_token: localStorage.getItem('intime_token') || '',
    user: {}
  },
  mutations: {
    add_period(state, payload) {
      state.reservation.period = payload;
      if (state.reservation.current_step <= 1) {
        state.reservation.current_step = 2;
      }
    },
    add_vehicle_category(state, payload) {
      state.reservation.vehicle = payload;
      if (state.reservation.current_step <= 2) {
        state.reservation.current_step = 3;
      }
    },
    add_extras(state, payload) {
      state.reservation.extras = payload;
      if (state.reservation.current_step <= 3) {
        state.reservation.current_step = 4;
      }
    },
    add_personal_data(state, payload) {
      state.reservation.personal_data = payload;
      if (state.reservation.current_step <= 4) {
        state.reservation.current_step = 5;
      }
    },
    reset_reservation(state) {
      state.reservation = {
        current_step: 1,
        period: {},
        vehicle: {},
        extras: {},
        personal_data: {}
      }
    },
    change_step(state, payload) {
      state.reservation.current_step = payload;
    },
    addToken (state, payload) {
      state.intime_token = payload;
    },
    logout (state, payload) {
      state.intime_token = payload;
      localStorage.setItem('intime_token', payload)
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    get_reservation: state => {
      return state.reservation
    },
    getToken: state => {
      return state.intime_token
    },
    isLoggedIn: state => !!state.intime_token,
    getUser: state => {
      return state.user
    }
  },
  plugins: [vuexLocal.plugin]
})
