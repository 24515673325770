import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'home', component: () => import('../views/home.vue') },
  { path: '/reservations/new', name: 'reservations', component: () => import('../views/reservations/new.vue') },
  { path: '/reservations/:uuid', name: 'reservation', component: () => import('../views/reservations/show.vue') },
  { path: '/vehicles', name: 'vehicles', component: () => import('../views/vehicles/index.vue') },

  { path: '/contact', name: 'contact', component: () => import('../views/contact.vue') },
  { path: '/legal', name: 'legal', component: () => import('../views/legal.vue') },
  { path: '/privacy', name: 'privacy', component: () => import('../views/privacy.vue') },
  { path: '/terms', name: 'terms', component: () => import('../views/terms.vue') },

  { path: '/auth/sign_in', name: 'auth_sign_in', component: () => import('../views/auth/sign_in.vue') },
  { path: '/auth/sign_up', name: 'auth_sign_up', component: () => import('../views/auth/sign_up.vue') },

  /* User Area */

  { path: '/account/overview', name: 'account_overview', component: () => import('../views/account/overview/index.vue') },

  { path: '/account/payment_methods', name: 'account_payment_methods', component: () => import('../views/account/payment_methods/index.vue') },
  { path: '/account/payment_methods/new', name: 'new_account_payment_method', component: () => import('../views/account/payment_methods/new.vue') },

  { path: '/account/reservations', name: 'account_reservations', component: () => import('../views/account/reservations/index.vue') },
  { path: '/account/settings', name: 'account_settings', component: () => import('../views/account/settings/index.vue') },

  /* Locations */
  { path: '/locations', name: 'locations', component: () => import('../views/locations/index.vue') },
  { path: '/locations/:id', name: 'location', component: () => import('../views/locations/show.vue') },

  { path: '/invoices/:uuid', name: 'invoice', component: () => import('../views/invoices/show.vue') },

  { path: '/contracts/:uuid', name: 'invoice', component: () => import('../views/contracts/show.vue') },
  { path: '/handovers/:uuid', name: 'handover', component: () => import('../views/handovers/show.vue') },
  { path: '/returns/:uuid', name: 'return', component: () => import('../views/returns/show.vue') },


  /* Admin Area */

  { path: '/admin/dashboard', name: 'admin_dashboard', component: () => import('../views/admin/dashboard/index.vue') },

  { path: '/admin/reservations', name: 'admin_reservations', component: () => import('../views/admin/reservations/index.vue') },
  { path: '/admin/reservations/new', name: 'new_admin_reservation', component: () => import('../views/admin/reservations/new.vue') },
  { path: '/admin/reservations/:id', name: 'admin_reservation', component: () => import('../views/admin/reservations/show.vue') },

  { path: '/admin/vehicles', name: 'admin_vehicles', component: () => import('../views/admin/vehicles/index.vue') },
  { path: '/admin/vehicles/new', name: 'new_admin_vehicle', component: () => import('../views/admin/vehicles/new.vue') },
  { path: '/admin/vehicles/:id', name: 'admin_vehicle', component: () => import('../views/admin/vehicles/show.vue') },

  { path: '/admin/vehicles/:id/damages', name: 'admin_vehicle_damages', component: () => import('../views/admin/vehicles/damages/index.vue') },
  { path: '/admin/vehicles/:id/damages/new', name: 'new_admin_vehicle_damage', component: () => import('../views/admin/vehicles/damages/new.vue') },
  { path: '/admin/vehicles/:id/damages/:damage_id', name: 'admin_vehicle_damage', component: () => import('../views/admin/vehicles/damages/show.vue') },

  { path: '/admin/vehicles/:id/repairs', name: 'admin_vehicle_repairs', component: () => import('../views/admin/vehicles/repairs/index.vue') },
  { path: '/admin/vehicles/:id/repairs/new', name: 'new_admin_vehicle_repair', component: () => import('../views/admin/vehicles/repairs/new.vue') },
  { path: '/admin/vehicles/:id/repairs/:repair_id', name: 'admin_vehicle_repair', component: () => import('../views/admin/vehicles/repairs/show.vue') },

  { path: '/admin/vehicles/:id/reservations', name: 'admin_vehicle_reservations', component: () => import('../views/admin/vehicles/reservations/index.vue') },

  { path: '/admin/vehicles/:id/insurance', name: 'admin_vehicle_insurance', component: () => import('../views/admin/vehicles/insurance/index.vue') },

  { path: '/admin/vehicles/:id/documents', name: 'admin_vehicle_documents', component: () => import('../views/admin/vehicles/documents/index.vue') },
  { path: '/admin/vehicles/:id/documents/new', name: 'new_admin_vehicle_document', component: () => import('../views/admin/vehicles/documents/new.vue') },

  { path: '/admin/vehicle_makes', name: 'admin_vehicle_makes', component: () => import('../views/admin/vehicle_makes/index.vue') },
  { path: '/admin/vehicle_makes/new', name: 'new_admin_vehicle_make', component: () => import('../views/admin/vehicle_makes/new.vue') },
  { path: '/admin/vehicle_makes/:id', name: 'admin_vehicle_make', component: () => import('../views/admin/vehicle_makes/show.vue') },

  { path: '/admin/vehicle_groups', name: 'admin_vehicle_groups', component: () => import('../views/admin/vehicle_groups/index.vue') },
  { path: '/admin/vehicle_groups/new', name: 'new_admin_vehicle_group', component: () => import('../views/admin/vehicle_groups/new.vue') },
  { path: '/admin/vehicle_groups/:id', name: 'admin_vehicle_group', component: () => import('../views/admin/vehicle_groups/show.vue') },

  { path: '/admin/vehicle_categories', name: 'admin_vehicle_categories', component: () => import('../views/admin/vehicle_categories/index.vue') },
  { path: '/admin/vehicle_categories/new', name: 'new_admin_vehicle_category', component: () => import('../views/admin/vehicle_categories/new.vue') },
  { path: '/admin/vehicle_categories/:id', name: 'vehicle_category', component: () => import('../views/admin/vehicle_categories/show.vue') },

  { path: '/admin/vehicle_categories/:id/prices', name: 'vehicle_category_prices', component: () => import('../views/admin/vehicle_categories/prices/index.vue') },

  { path: '/admin/vehicle_categories/:id/extras', name: 'vehicle_category_extras', component: () => import('../views/admin/vehicle_categories/extras/index.vue') },

  { path: '/admin/vehicle_extras', name: 'admin_vehicle_extras', component: () => import('../views/admin/vehicle_extras/index.vue') },
  { path: '/admin/vehicle_extras/new', name: 'new_admin_vehicle_extra', component: () => import('../views/admin/vehicle_extras/new.vue') },
  { path: '/admin/vehicle_extras/:id', name: 'admin_vehicle_extra', component: () => import('../views/admin/vehicle_extras/show.vue') },

  { path: '/admin/locations', name: 'admin_locations', component: () => import('../views/admin/locations/index.vue') },
  { path: '/admin/locations/new', name: 'new_admin_location', component: () => import('../views/admin/locations/new.vue') },
  { path: '/admin/locations/:id', name: 'admin_location', component: () => import('../views/admin/locations/show.vue') },

  { path: '/admin/locations/:id/hours', name: 'admin_location_hours', component: () => import('../views/admin/locations/hours/index.vue') },

  { path: '/admin/locations/:id/users', name: 'admin_location_users', component: () => import('../views/admin/locations/users/index.vue') },
  { path: '/admin/locations/:id/users/new', name: 'new_admin_location_user', component: () => import('../views/admin/locations/users/new.vue') },

  { path: '/admin/customers', name: 'admin_customers', component: () => import('../views/admin/customers/index.vue') },
  { path: '/admin/customers/new', name: 'new_admin_customer', component: () => import('../views/admin/customers/new.vue') },
  { path: '/admin/customers/:id', name: 'admin_customer', component: () => import('../views/admin/customers/show.vue') },

  { path: '/admin/settings', name: 'admin_settings', component: () => import('../views/admin/settings/index.vue') },

  { path: '/admin/documents', name: 'admin_documents', component: () => import('../views/admin/documents/index.vue') },
  { path: '/admin/documents/:id', name: 'admin_document', component: () => import('../views/admin/documents/show.vue') },

  { path: '/admin/calendar', name: 'admin_calendar', component: () => import('../views/admin/calendar/index.vue') },

  { path: '/admin/availability', name: 'admin_availability', component: () => import('../views/admin/availability/index.vue') },

  { path: '/admin/statistics', name: 'admin_statistics', component: () => import('../views/admin/statistics/index.vue') },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
